.am-action-sheet-wrap {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.am-action-sheet-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  z-index: 1000;
}

.am-action-sheet-mask-hidden {
  display: none;
}

.am-action-sheet-close {
  display: none;
}

.am-action-sheet {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding-bottom: env(safe-area-inset-bottom);
}

.am-action-sheet.am-action-sheet-share {
  background-color: #f2f2f2;
}

.am-action-sheet-title,
.am-action-sheet-message {
  margin: 0.4rem auto;
  padding: 0 0.4rem;
  text-align: center;
}

.am-action-sheet-title {
  font-size: 0.453333rem;
}

.am-action-sheet-message {
  color: #888;
  font-size: 0.373333rem;
}

.am-action-sheet-button-list {
  text-align: center;
  color: #000;
}

.am-action-sheet-button-list-item {
  font-size: 0.48rem;
  padding: 0 0.213333rem;
  margin: 0;
  position: relative;
  height: 1.333333rem;
  line-height: 1.333333rem;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  border-top: 1PX solid #ddd;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-action-sheet-button-list-item {
    border-top: none;
  }

  html:not([data-scale]) .am-action-sheet-button-list-item::before {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-action-sheet-button-list-item::before {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}

.am-action-sheet-button-list-item.am-action-sheet-button-list-item-active {
  background-color: #ddd;
}

.am-action-sheet-button-list-badge {
  display: flex;
  align-items: center;
  justify-content: center;
}

.am-action-sheet-button-list-badge .am-badge {
  margin-left: 0.213333rem;
  flex-shrink: 0;
}

.am-action-sheet-button-list-item-content {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.am-action-sheet-button-list .am-action-sheet-cancel-button {
  padding-top: 0.16rem;
  position: relative;
}

.am-action-sheet-button-list .am-action-sheet-cancel-button-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.16rem;
  background-color: #e7e7ed;
  border-top: 1PX solid #ddd;
  border-bottom: 1PX solid #ddd;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-action-sheet-button-list .am-action-sheet-cancel-button-mask {
    border-top: none;
  }

  html:not([data-scale]) .am-action-sheet-button-list .am-action-sheet-cancel-button-mask::before {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-action-sheet-button-list .am-action-sheet-cancel-button-mask::before {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-action-sheet-button-list .am-action-sheet-cancel-button-mask {
    border-bottom: none;
  }

  html:not([data-scale]) .am-action-sheet-button-list .am-action-sheet-cancel-button-mask::after {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-action-sheet-button-list .am-action-sheet-cancel-button-mask::after {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}

.am-action-sheet-button-list .am-action-sheet-destructive-button {
  color: #f4333c;
}

.am-action-sheet-share-list {
  display: flex;
  position: relative;
  border-top: 1PX solid #ddd;
  padding: 0.56rem 0 0.56rem 0.4rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-action-sheet-share-list {
    border-top: none;
  }

  html:not([data-scale]) .am-action-sheet-share-list::before {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-action-sheet-share-list::before {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}

.am-action-sheet-share-list-item {
  flex: none;
  margin: 0 0.32rem 0 0;
}

.am-action-sheet-share-list-item-icon {
  margin-bottom: 0.24rem;
  width: 1.6rem;
  height: 1.6rem;
  background-color: #fff;
  border-radius: 0.08rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.am-action-sheet-share-list-item-title {
  color: #888;
  font-size: 0.266667rem;
  text-align: center;
}

.am-action-sheet-share-cancel-button {
  height: 1.333333rem;
  line-height: 1.333333rem;
  text-align: center;
  background-color: #fff;
  color: #000;
  font-size: 0.48rem;
  position: relative;
  border-top: 1PX solid #ddd;
  box-sizing: border-box;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-action-sheet-share-cancel-button {
    border-top: none;
  }

  html:not([data-scale]) .am-action-sheet-share-cancel-button::before {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-action-sheet-share-cancel-button::before {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}

.am-action-sheet-share-cancel-button.am-action-sheet-share-cancel-button-active {
  background-color: #ddd;
}